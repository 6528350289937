import { Box, Button, Card, DialogTitle, Divider, IconButton, ListItemAvatar, Stack, Typography } from '@mui/material'
import React from 'react'
import DialogBody from '../dialog-body'
import DialogFooter from '../dialog-footer'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { fToNow } from 'src/utils/format-time';
import { notificationsTypes } from 'src/store/notifications/notificationsTypes';
import Image from '../image';
import { useState } from 'react'
import Lightbox from '../lightbox'
import {useResponsive} from 'src/hooks/use-responsive'

export default function Notification(props) {
  const { resetNotificationsLayout, selectedNotification } = props
  const [showImages, setShowImages] = useState(false)
  const smDown = useResponsive("down", "sm")

  return (
    <>
      <DialogTitle
        sx={{
          padding: "20px",
          backgroundColor: '#F4F6F8'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <IconButton size="large" onClick={resetNotificationsLayout}>
            <ChevronLeft size="small" />
          </IconButton>
          <Typography variant='h5' sx={{ color: '#454F5B' }}>
            {notificationsTypes[selectedNotification.notification_type]?.label}
          </Typography>
          <Box sx={{ width: '48px' }} />
          {/* <IconButton size="large">
            <DeleteOutlineIcon />
          </IconButton> */}
        </Stack>
      </DialogTitle>
      <DialogBody>
        <Card sx={{ padding: '16px', borderRadius: '12px' }}>
          <Stack sx={{ gap: '10px' }} alignItems='center' direction='row'>
            <ListItemAvatar sx={{ height: 24, marginRight: 0 }}>
              {notificationsTypes[selectedNotification.notification_type]?.itemAvatar}
            </ListItemAvatar>
            <Typography variant='caption' sx={{ color: '#637381' }}>
              {fToNow(selectedNotification?.created_at)}
            </Typography>
          </Stack>

          <Typography variant='h6' sx={{ color: '#212B36' }} mt={1.5} mb={2}>
            {selectedNotification?.title}
          </Typography>
          {selectedNotification?.image ? (
            <>
              {!smDown && (
                <Lightbox
                  open={!!showImages}
                  close={() => setShowImages(false)}
                  slides={[{ src: selectedNotification?.image }]}
                  disabledZoom={true}
                  disabledTotal={true}
                  disabledVideo={true}
                  disabledCaptions={true}
                  disabledSlideshow={true}
                  disabledThumbnails={true}
                  disabledFullscreen={true}
                />
              )}


              <Image
                src={selectedNotification?.image}
                onClick={() => setShowImages(1)}
                disabledEffect
                sx={{ borderRadius: 1, cursor: "pointer" }}
              />
            </>

          ) : (
            <Divider />
          )}

          <Typography variant='body2' sx={{ color: '#454F5B' }} mt={1.5}>
            <div dangerouslySetInnerHTML={{ __html: selectedNotification?.long_message.replace(/\n/g, "<br />") }} />
          </Typography>
        </Card>
      </DialogBody>
      {(selectedNotification?.action_link && selectedNotification?.action_message) && (
        <DialogFooter>
          <Button
            fullWidth
            variant='contained'
            size='large'
            onClick={() => {
              window.location.replace(selectedNotification?.action_link)
            }}
          >
            {selectedNotification?.action_message}
          </Button>
        </DialogFooter>
      )}
    </>
  )
}

